<template>
  <el-autocomplete
    class="inline-input"
    v-model="queryString"
    :fetch-suggestions="querySearch"
    placeholder="请输入车牌/自编号"
    clearable
    @select="handleSelect"
  >
    <!-- 展示形式 -->
    <el-select
      class="w-105"
      :value="value"
      @input="showTypeInput"
      slot="prepend"
    >
      <el-option
        v-for="item in showTypes"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
        {{ item.label }}
      </el-option>
    </el-select>

    <!-- 层级类型 -->
    <el-dropdown
      slot="append"
      size="small"
      trigger="click"
      @command="handleCommand"
    >
      <el-button icon="el-icon-setting"></el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="refresh">
          <i class="el-icon-refresh"></i>刷新
        </el-dropdown-item>
        <el-dropdown-item command="company">按企业层级</el-dropdown-item>
        <el-dropdown-item command="area">按区域层级</el-dropdown-item>
        <!-- <el-dropdown-item command="3">按企业+区域层级</el-dropdown-item>
          <el-dropdown-item command="4">按区域+企业层级</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>

    <!-- </el-input> -->
  </el-autocomplete>
</template>

<script>
import XEUtils from 'xe-utils'
export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      queryString: '',
      showTypes: [
        { label: '车牌号', value: 1 },
        { label: '自编号', value: 2 },
        { label: '车牌(自编)', value: 3 }
      ]
    }
  },
  methods: {
    handleSelect (item) {
      this.$emit('checked', item)
    },

    querySearch (queryString, cb) {
      queryString = queryString.toLowerCase()
      const suggestList = []
      const { length } = queryString.replace(/[^a-zA-Z0-9]/g, '')
      const data = this.data
      if (length >= 2) {
        XEUtils.eachTree(data, ({ id, type, detail }) => {
          if (type == 'DEV') {
            const busLicPlate = detail.busLicPlate || ''
            const ownerCode = detail.ownerCode || ''
            const searchStr = (busLicPlate + ownerCode).toLowerCase()
            if (searchStr.includes(queryString)) {
              suggestList.push({
                value: `${busLicPlate}(${ownerCode})`,
                id,
                detail,
                type
              })
            }
          }
        })
      }
      cb(suggestList)
    },

    showTypeInput (value) {
      this.$emit('input', value)
    },

    handleCommand (command) {
      if (command == 'refresh') {
        this.$emit('refresh')
      } else {
        this.$emit('change-type', command)
      }
    }
  }
}
</script>
