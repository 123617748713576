export function busDataFormat (node) {
  const lngLat = node.lngLat ? (node.lngLat.split(',')) : [0, 0]
  const nodeFormat = {
    acc: !!node.acc,
    accName: node.acc ? '开' : '关',
    angle: node.angle,
    busID: node.busID,
    busLicPlate: node.busLicPlate,
    compId: node.compId,
    compName: node.compName,
    companyID: node.companyID,
    deviId: node.deviId,
    time: node.lastFixDatTim,
    lngLat: node.lngLat,
    lng: lngLat[0],
    lat: lngLat[1],
    mapList: node.mapList,
    offset: node.offset,
    isOnline: !!node.onLineStat,
    isOnlineStr: node.onLineStat ? '在线' : '离线',
    ownType: node.ownType,
    ownerCode: node.ownerCode,
    parentBusID: node.parentBusID,
    remark: node.remark,
    weight: node.weight,
    speed: node.speed,
    speedName: node.speed + 'km/h',
    tirePresJson: node.tirePresJson,
    vehiId: node.vehiId,
    vehiStatId: node.vehiStatId,
    address: node.address || '',
    mileage: node.mileage || ''
    // addressLoaded: false, // 是否加载了地址
  }
  return nodeFormat
}

// 检测是否离线
// function checkedOnlineStatus(node) {
//   if(!!Number(node.onLineStat)) {
//     // (后期最好改为用服务器时间)
//     const serverTime = Date.now()
//     const dateGps = moment(node.lastFixDatTim)
//     const diffSeconds = moment(serverTime).diff(dateGps, 'seconds') // 计算相差的秒数
//     // let offlineSecond = !!node.acc ? 60 : 305
//     let offlineSecond = 300 // 五分钟
//     return !!(diffSeconds < offlineSecond)
//   }
// }
