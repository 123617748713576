<template>
  <resize-wrap class="h-100-p">
    <template v-slot="{height}">
      <virtual-tree
        v-loading="loading"
        ref="treeRef"
        @node-bdlclick="$emit('dblclick-node',$event)"
        @checked-node="checkedNode"
        @radio-change="radioChange"
        :height="height"
        :check-type="checkType"
      >
        <!-- 搜索栏 -->
        <template v-slot:header="{treeData}">
          <bus-tree-search-bar
            ref="searchbar"
            class="mb-8 w-100-p"
            :data="treeData"
            v-model="labelType"
            @refresh="refreshTree"
            @change-type="treeTypeChange"
            @checked="activedNode"
          />
        </template>

        <!-- 标题 -->
        <template v-slot:title="{item}">
          <!-- title -->
          {{getLabel(item, labelType)}}
          <!-- 在线数 -->
          <template v-if="item.totalNum>=0">({{item.onlineNum||0}}/{{item.totalNum}})</template>
        </template>

        <!-- 图标 -->
        <template v-slot:prefixLeaf="{item}">
          <img
            v-if="item.type=='DEV'"
            :style="!(item.detail&&item.detail.isOnline)?'filter:grayscale(1)':''"
            src="@/assets/imgs/common/tree_icon_online.png"
          >
        </template>

        <!-- 功能按钮 -->
        <template v-slot:suffix="{item}">
          <template v-if="item.type=='DEV'"></template>
        </template>
      </virtual-tree>
    </template>
  </resize-wrap>
</template>

<script>
import XEUtils from 'xe-utils'
import VirtualTree from '@/components/VirtualTree'
import BusTreeSearchBar from '@/components/BusTree/ToolsBar'
import { getBusTree, getBusList } from '@/apis/monitor/carMonitor'
import { addMessageLisner } from '@/utils/websocket'
import { busDataFormat } from './formatBusData'
import moment from 'moment'
export default {
  props: {
    checkType: { type: String, default: 'checkbox' }
  },
  data () {
    return {
      labelType: 1,
      loading: false,
      serverDiff: 0, // serverHs - currHs == serverDiff
      calcTimeout: 5000, // 计算在线数量频率，数据越大频率月底
      calcTimer: null,
      showType: ''
    }
  },
  async mounted () {
    // 初始化树的数据
    await this.initBusTree()

    // 接口请求回来3s之后 开始推送
    setTimeout(() => {
      addMessageLisner(24, this.onWsMessage)
    }, 3000)
  },
  beforeDestroy () {
    clearTimeout(this.calcTimer)
    this.calcTimer = null
  },
  methods: {
    formatWsData (node) {
      const lng = XEUtils.round(node.DimX / 1000000, 6)
      const lat = XEUtils.round(node.DimY / 1000000, 6)
      return {
        lng,
        lat,
        lngLat: [lng, lat],
        angle: node.Angle,
        vehiId: node.vehiId,
        time: XEUtils.toDateString(node.LastFixTime),
        mileage: node.Mileage,
        speed: node.Speed,
        speedName: node.Speed + 'km/h',
        isOnline: true,
        isOnlineStr: '在线'
      }
    },

    onWsMessage (data) {
      data.forEach(item => {
        const newNode = this.formatWsData(item)
        this.onDriverMessage(newNode)
      })
    },

    updateBusTree () {
      setTimeout(() => {
        getBusList().then((res) => {
          if (res.code == 200) {
            res.data.forEach((node) => {
              // 格式化数据，保持和websocket一致
              const newNode = busDataFormat(node)
              this.onDriverMessage(newNode)
            })
            this.updateBusTree()
          } else {
            this.$message.error(res.msg)
          }
        })
      }, 10000)
    },

    async initListAndMarkers () {
      const res = await getBusList()
      if (res.code == 200) {
        this.tableDataMap = new Map()
        const tableData = this.tableData = res.data.map((node) => {
          // 格式化数据，保持和websocket一致
          node = busDataFormat(node)
          this.tableDataMap.set(node.vehiId, node)
          return node
        })
        this.initMarkers(tableData)
        // 更新经纬度对应的地址
        setTimeout(() => {
          this.initRowAddress()
        }, 5000)
      } else {
        this.$message.error(res.msg)
      }
    },

    checkedNode () {
      this.$emit('checked-node', this.$refs.treeRef.getLeafCheckedIds('id'))
    },

    radioChange () {
      this.$emit('radio-change', this.$refs.treeRef.getRadioNode())
    },

    setRadio (id) {
      this.$refs.treeRef.setRadio = id
    },
    async getBusData (cancel, cache) {
      const data = []
      const companyTask = getBusTree({ key: 'COMPANY', parentKey: '' }, cancel, cache).then((res) => {
        data.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: (res.data.rtmTreeList.treeList || [])
        })
      })
      const busTask = getBusTree({ key: 'DEV', parentKey: 'COMPANY' }, cancel, cache).then((res) => {
        data.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: (res.data.rtmTreeList.treeList || [])
        })
      })

      await Promise.all([companyTask, busTask])
      return data
    },
    // cancel 是否切换页面取消接口， cache 是否取缓存的数据
    async initBusTree (cancel, cache) {
      this.loading = true
      const data = await this.getBusData(cancel, cache)
      let list = [{ id: '0', name: '全部车辆', totalNum: 0, onlineNum: 0, type: 'ROOT' }]
      let carCount = 0
      data.forEach(({ tree0List }, index) => { // 执行n条数据次
        tree0List = tree0List.map(item => {
          // 车辆节点需要 格式化, 保持和websocket一致
          if (item.type == 'DEV') {
            item.detail = busDataFormat(item.detail)
            carCount++
          } else {
            item.totalNum = 0
            item.onlineNum = 0
          }
          item.pId = item.pid || '0'
          return item
        })

        list.push(...tree0List)
        this.calcTimeout = carCount > 2000 ? carCount : 1000
      })

      // 需要过滤调子节点为0的类型：省，市，区，企业，机构
      const filterType = ['PROVINCE', 'CITY', 'DISTRICT', 'COMPANY', 'ORG']
      list = list.filter(item => {
        if (filterType.includes(item.type)) {
          const isHava = list.some(subItem => (item.id == subItem.pId))
          return isHava
          // 不需要处理的直接返回
        } else {
          return true
        }
      })

      // 把list转为tree
      const treeData = XEUtils.toArrayTree(list, { key: 'id', parentKey: 'pId' })
      // 初始化树组件
      this.$refs.treeRef.loadTree(treeData)
      // 选中全部车辆
      this.$refs.treeRef.toogleNodeChecks(['0'], true)
      // 展开全部车辆
      this.$refs.treeRef.setTreeExpand('0', true)
      this.loading = false

      // 定时计算在线数量
      this.calcTimer = this.computeBusOnlineNum()
    },

    refreshTree () {
      this.$emit('refresh')
      const showType = this.showType || 'company'
      this.treeTypeChange(showType)
    },

    treeTypeChange (type) {
      this.showType = type
      if (type == 'company') {
        clearTimeout(this.calcTimer)
        this.calcTimer = null
        this.initBusTree(false, false)
      } else if (type == 'area') {
        clearTimeout(this.calcTimer)
        this.calcTimer = null
        this.initBusTree2(false, false)
      }
    },

    async getBusData2 (cancel, cache) {
      const data = []
      const data2 = []
      const data3 = []
      const data4 = []
      const data5 = []
      const area1 = getBusTree({ key: 'PROVINCE', parentKey: '' }, cancel, cache).then((res) => {
        data.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: res.data.rtmTreeList.treeList || []
        })
      })
      const area2 = getBusTree({ key: 'CITY', parentKey: 'PROVINCE' }, cancel, cache).then((res) => {
        data2.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: res.data.rtmTreeList.treeList || []
        })
      })
      const area3 = getBusTree({ key: 'DISTRICT', parentKey: 'CITY' }, cancel, cache).then((res) => {
        data3.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: res.data.rtmTreeList.treeList || []
        })
      })
      const area4 = getBusTree({ key: 'COMPANY', parentKey: 'DISTRICT' }, cancel, cache).then((res) => {
        data4.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: res.data.rtmTreeList.treeList || []
        })
      })
      const bus = getBusTree({ key: 'DEV', parentKey: 'COMPANY' }, cancel, cache).then((res) => {
        data5.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: res.data.rtmTreeList.treeList || []
        })
      })

      await Promise.all([
        area1,
        area2,
        area3,
        area4,
        bus
      ])
      return [...data, ...data2, ...data3, ...data4, ...data5]
    },

    async initBusTree2 (cancel, cache) {
      this.loading = true
      const data = await this.getBusData2(cancel, cache)
      let list = [{ id: '0', name: '全部车辆', totalNum: 0, onlineNum: 0, type: 'ROOT' }]
      let carCount = 0

      data.forEach(({ tree0List }, index) => { // 执行n条数据次
        tree0List = tree0List.map(item => {
          // 车辆节点需要 格式化, 保持和websocket一致
          if (item.type == 'DEV') {
            item.detail = busDataFormat(item.detail)
            carCount++
          } else {
            item.totalNum = 0
            item.onlineNum = 0
          }
          item.pId = item.pid || '0'
          return item
        })

        list.push(...tree0List)
        this.calcTimeout = carCount > 2000 ? carCount : 1000
      })

      // 需要过滤调子节点为0的类型：省，市，区，企业，机构
      // const filterType = ['PROVINCE', 'CITY', 'DISTRICT', 'COMPANY', 'ORG']
      const filterType = ['CITY', 'DISTRICT', 'COMPANY', 'ORG']
      list = list.filter(item => {
        if (filterType.includes(item.type)) {
          const isHava = list.some(subItem => (item.id == subItem.pId))
          return isHava
          // 不需要处理的直接返回
        } else {
          return true
        }
      })

      // 把list转为tree
      const treeData = XEUtils.toArrayTree(list, { key: 'id', parentKey: 'pId' })

      // 初始化树组件
      this.$refs.treeRef.loadTree(treeData)
      // 选中全部车辆
      this.$refs.treeRef.toogleNodeChecks(['0'], true)
      // 展开全部车辆
      this.$refs.treeRef.setTreeExpand('0', true)
      this.loading = false

      // 定时计算在线数量
      this.calcTimer = this.computeBusOnlineNum()
    },

    activedNode (item) {
      // 筛选并选中某个节点
      if (this.checkType == 'checkbox') {
        this.$refs.treeRef.activeNode(item.id)
      } else {
        this.$refs.treeRef.activeNode(item.id)
        this.$refs.treeRef.setRadio(item.id)
      }
      this.$emit('actived-node', item)
    },

    // 获取节点的名称
    getLabel ({ name, type, detail }, labelType) {
      if (type == 'DEV') {
        const busLicPlate = `${[1, 3].includes(labelType) ? detail.busLicPlate : ''}`
        const prefix = labelType == 3 ? '(' : ''
        const suffix = labelType == 3 ? ')' : ''
        const ownerCode = `${[2, 3].includes(labelType) ? detail.ownerCode : ''}`
        return busLicPlate + prefix + ownerCode + suffix
      } else if (type == 'COMPANY') {
        return name + (detail && detail.productStatus == 2 ? '【有资质未生产】' : '')
      } else {
        return name
      }
    },

    // websocket数据更新车数据
    onDriverMessage (newNode) {
      // console.log(msg, 'msg')
      const id = 'v:' + newNode.vehiId
      const { time, isOnline, isOnlineStr, speed } = newNode
      this.$refs.treeRef.updateNodeData(id, { detail: { time, isOnline, isOnlineStr, speed } })
    },

    // 计算车的在线数量，并检测是否离线
    computeBusOnlineNum () {
      const treeData = this.$refs.treeRef.getTreeData()
      if (treeData) {
        treeData.forEach((item) => {
          // 递归计算并更新节点的在线数量
          this.calCurrentNodeNum(item)
        })
      }

      return setTimeout(() => {
        this.calcTimer = this.computeBusOnlineNum()
      }, this.calcTimeout)
    },

    // 计算节点下车辆在线数，计算节点下车辆总数
    calCurrentNodeNum (node) {
      const pEnums = ['ROOT', 'PROVINCE', 'CITY', 'DISTRICT', 'COMPANY', 'ORG']
      const sumObj = { totalNum: 0, onlineNum: 0 }

      if (pEnums.includes(node.type) && node.children && node.children.length > 0) {
        node.children.forEach((item) => {
          if (item.type == 'DEV') {
            const currOnline = item.detail.isOnline
            // 检测是否离线
            // const isOnline = this.checkedOnlineStatus(item)

            // 车辆离线
            // if(currOnline && !isOnline) {
            //   this.$refs.treeRef.updateNodeData(item.treeId, { detail: { isOnline, isOnlineStr: '离线'} })
            //   currOnline = isOnline
            //   this.$emit('offline')
            // }

            // 如果是在线就增加在线数量
            sumObj.onlineNum += currOnline ? 1 : 0
            sumObj.totalNum += 1
          } else {
            const { onlineNum, totalNum } = this.calCurrentNodeNum(item)
            sumObj.onlineNum += onlineNum
            sumObj.totalNum += totalNum
          }
        })

        // 更新节点数据
        this.$refs.treeRef.updateNodeData(node.id, sumObj)
      }
      return sumObj
    },

    // 检测是否离线
    checkedOnlineStatus (node) {
      if (node.detail.isOnline) {
        // 获取服务器时间
        const serverTime = this.serverDiff + Date.now()
        const dateGps = moment(node.detail.time)
        const diffSeconds = moment(serverTime).diff(dateGps, 'seconds') // 计算相差的秒数
        const offlineSecond = node.detail.acc ? 60 : 305
        return !!(diffSeconds > offlineSecond)
      }
    }

  },
  components: {
    VirtualTree,
    BusTreeSearchBar
  }
}
</script>
