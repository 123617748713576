var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resize-wrap',{staticClass:"h-100-p",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var height = ref.height;
return [_c('virtual-tree',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"treeRef",attrs:{"height":height,"check-type":_vm.checkType},on:{"node-bdlclick":function($event){return _vm.$emit('dblclick-node',$event)},"checked-node":_vm.checkedNode,"radio-change":_vm.radioChange},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var treeData = ref.treeData;
return [_c('bus-tree-search-bar',{ref:"searchbar",staticClass:"mb-8 w-100-p",attrs:{"data":treeData},on:{"refresh":_vm.refreshTree,"change-type":_vm.treeTypeChange,"checked":_vm.activedNode},model:{value:(_vm.labelType),callback:function ($$v) {_vm.labelType=$$v},expression:"labelType"}})]}},{key:"title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLabel(item, _vm.labelType))+" "),(item.totalNum>=0)?[_vm._v("("+_vm._s(item.onlineNum||0)+"/"+_vm._s(item.totalNum)+")")]:_vm._e()]}},{key:"prefixLeaf",fn:function(ref){
var item = ref.item;
return [(item.type=='DEV')?_c('img',{style:(!(item.detail&&item.detail.isOnline)?'filter:grayscale(1)':''),attrs:{"src":require("@/assets/imgs/common/tree_icon_online.png")}}):_vm._e()]}},{key:"suffix",fn:function(ref){
var item = ref.item;
return [(item.type=='DEV')?void 0:_vm._e()]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }